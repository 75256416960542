import Table from 'react-bootstrap/Table';
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {getIngredient} from "../api/ingredients";

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Button from "react-bootstrap/Button";
import {UseAppContext} from "../hooks/appContext";
import {isStaff} from "../api/auth";


function indexToQuart(index) {
    switch (index) {
        case "QUART":
            return 1;
        case "PINT":
            return 0.5;
        case "OUNCE":
            return 1/32;
        case "GALLON":
            return 4;
        default:
            return 1;
    }
}

function sum_up_field(ingredients, field, precision= undefined) {
    const value = +(ingredients.map(toner => toner[field]).reduce((a, b) => a + b, 0));
    if (precision === undefined) {
        return value;
    } else {
        return value.toFixed(precision);
    }
}


export default function Dispense(props, ref) {

    const { id } = props;

    const [loggedIn, setLoggedIn, handleHttpErrors] = UseAppContext();
    const [colorDetail, setColorDetail] = useState();

    const [index, setIndex] = useState("QUART");
    const [volume, setVolume] = useState(1);
    const [customerDiscount, setCustomerDiscount] = useState(0);

    useEffect(() => {
        if (colorDetail) {
            return;
        }

        getIngredient(id).then(data => {
            setColorDetail(data.data);
        }).catch(handleHttpErrors);
    }, [colorDetail]);


    if (!colorDetail) {
        return (<></>);
    }

    const totalAmount = sum_up_field(colorDetail.ingredients, "amount");
    const indexInQt = indexToQuart(index);
    const volumeInQt = indexInQt * Number(volume);

    colorDetail.ingredients.forEach(ingredient => {
        ingredient.weight_portion = ingredient.amount / totalAmount;

        const lbpgl = ingredient.density_lbpgl;
        ingredient.density_grplt = +(lbpgl * 119.826).toFixed(2);
        ingredient.density_grpml = +(ingredient.density_grplt / 1000).toFixed(2);

        ingredient.density_grplt_percent = +(ingredient.density_grplt * ingredient.weight_portion).toFixed(2);
        ingredient.density_lbpgl_percent = +(ingredient.density_lbpgl * ingredient.weight_portion).toFixed(2);
    });

    const average_density = sum_up_field(colorDetail.ingredients, "density_grplt_percent");
    const average_density_lbpgl = sum_up_field(colorDetail.ingredients, "density_lbpgl_percent");
    const weight_96_qt = +(average_density * 0.946 * 0.96);

    colorDetail.ingredients.forEach(ingredient => {
        ingredient.formula_amount = +(ingredient.weight_portion * weight_96_qt * volumeInQt).toFixed(2);
        ingredient.price = +(ingredient.price_pqt * ingredient.weight_portion * (1 - (ingredient.discount / 100))).toFixed(2);
    });

    const total_formula_amount = sum_up_field(colorDetail.ingredients, "formula_amount");
    const total_price = +(sum_up_field(colorDetail.ingredients, "price") * volumeInQt).toFixed(2);
    const discounted_price = +(total_price * (1 - (Number(customerDiscount) / 100))).toFixed(2);

    return (
        <div ref={ref} className="p-2">
            <style type="text/css" media="print">
                {"@page { size: landscape; }"}
            </style>
            <Form>
                <Form.Group as={Row} className="mb-3" controlId="formUnit">
                    <Form.Label column sm="1">Unit</Form.Label>
                    <Col sm="3">
                        <Form.Select value={index} onChange={e => setIndex(e.target.value)}>
                            <option value="QUART">Quart</option>
                            <option value="PINT">Pint</option>
                            <option value="OUNCE">Ounce</option>
                            <option value="GALLON">Gallon</option>
                        </Form.Select>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="formVolume">
                    <Form.Label column sm="1">Volume</Form.Label>
                    <Col sm="3">
                        <Form.Control type="text" placeholder="Volume" value={volume}
                                      onChange={e => setVolume(e.target.value)}/>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="formDiscount">
                    <Form.Label column sm="1">Discount</Form.Label>
                    <Col sm="3">
                        <Form.Control type="text" placeholder="Discount" value={customerDiscount}
                                      onChange={e => setCustomerDiscount(e.target.value)}/>
                    </Col>
                </Form.Group>
            </Form>
            <div>
                <h3>
                    {colorDetail.manufacturer}/
                    {colorDetail.color_code}/
                    {colorDetail.color_name}/
                    {colorDetail.variant_description}/
                    {colorDetail.year}
                </h3>
                <div className="d-flex justify-content-center">
                    <Table striped bordered hover style={{borderRadius: '5px', overflow: 'hidden'}}>
                        <thead>
                        <tr>
                            <th>Product</th>
                            <th>Description</th>
                            {
                                isStaff() ? <>
                                    <th>Original Amt</th>
                                    <th>% WT</th>
                                    <th>Formula Amt</th>
                                    <th>Price/Qt</th>
                                    <th>Discount %</th>
                                    <th>Cost</th>
                                    <th>D lb/gal</th>
                                    <th>D g/L</th>
                                    <th>D g/ml</th>
                                    <th>D% g/L</th>
                                    <th>D% lb/gal</th>
                                </> : <>
                                    <th>Formula Amt</th>
                                </>
                            }
                        </tr>
                        </thead>
                        <tbody>
                        {
                            colorDetail["ingredients"].map(color => (
                                <tr key={`ingredient-${color.product}`}>
                                    <td>{color.product}</td>
                                    <td>{color.description}</td>
                                    {
                                        isStaff() ? <>
                                            <td>{color.amount}</td>
                                            <td>{(color.weight_portion * 100).toFixed(2)}</td>
                                            <td>{color.formula_amount}</td>
                                            <td>{color.price_pqt}</td>
                                            <td>{color.discount}%</td>
                                            <td>{color.price}</td>
                                            <td>{color.density_lbpgl}</td>
                                            <td>{color.density_grplt}</td>
                                            <td>{color.density_grpml}</td>
                                            <td>{color.density_grplt_percent}</td>
                                            <td>{color.density_lbpgl_percent}</td>
                                        </> : <>
                                            <td>{color.formula_amount}</td>
                                        </>
                                    }
                                </tr>))
                        }
                        <tr>
                            <td colSpan={13}></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Total</td>
                            {
                                isStaff() ? <>
                                    <td>{totalAmount.toFixed(2)}</td>
                                    <td>100%</td>
                                    <td>{total_formula_amount.toFixed(2)}</td>
                                    <td></td>
                                    <td></td>
                                    <td>{total_price.toFixed(2)}</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>{average_density.toFixed(2)}</td>
                                    <td>{average_density_lbpgl.toFixed(2)}</td>
                                </> : <>
                                    <td>{total_formula_amount.toFixed(2)}</td>
                                </>
                            }
                        </tr>
                        </tbody>
                    </Table>
                </div>
                <Table bordered hover style={{borderRadius: '5px', overflow: 'hidden'}}>
                    <tbody>
                    <tr>
                        <td>96% of WT</td>
                        <td>{+(0.96 * total_formula_amount).toFixed(2)}</td>
                    </tr>
                    <tr>
                        <td>Extra Discount</td>
                        <td>{customerDiscount}%</td>
                    </tr>
                    <tr>
                        <td>Final Price</td>
                        <td>{discounted_price}</td>
                    </tr>
                    </tbody>
                </Table>
            </div>
        </div>
    );
}