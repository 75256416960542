import {useFieldArray, useForm} from "react-hook-form";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {addIngredient, getIngredient, updateIngredient} from "../api/ingredients";
import Table from "react-bootstrap/Table";
import {Row} from "react-bootstrap";
import {v4 as uuidv4} from 'uuid';
import Container from "react-bootstrap/Container";
import {Typeahead} from "react-bootstrap-typeahead";
import {listToners} from "../api/toner";
import {UseAppContext} from "../hooks/appContext";


export function ColorEdit() {

    const [loggedIn, setLoggedIn, handleHttpErrors] = UseAppContext();
    const navigate = useNavigate();
    const { control, register, handleSubmit, watch, formState: { errors }, setValue, getValues} = useForm();
    const [formError, setFormError] = useState(null);
    const params = useParams();

    const shouldPut = !!params.id;
    const colorId = shouldPut ? params.id : uuidv4();
    const detailPage = `/color-detail/${colorId}`;

    const [toners, setToners] = useState();

    const [selectedToner, setSelectedToner] = useState();
    const [tonerAmount, setTonerAmount] = useState();

     useEffect(() => {
        if (toners) {
            return;
        }

        listToners().then(data => {
            setToners(data.data.results);
        }).catch(handleHttpErrors);
    }, [toners]);

    const propertyNames = [
        "color_code",
        "color_name",
        "manufacturer",
        "year",
        "variant_description",
        "color_reference",
        "formula_version",
        "formula_date",
        "made_by",
        "quality",
        "user_cost",
        "cost_code",
        "jobber_cost"
    ];

    const onSubmit = async data => {
        const submittingData = {... data};
        submittingData["color_id"] = colorId;

        propertyNames.forEach(propertyName => {
            if (!submittingData[propertyName] || submittingData[propertyName] === "") {
                delete submittingData[propertyName]
            }
        })

        if (shouldPut) {
            updateIngredient(colorId, submittingData).then(() => navigate(detailPage)).catch(handleHttpErrors);
        } else {
            addIngredient(submittingData).then(() => navigate(detailPage)).catch(handleHttpErrors);
        }
    }

    const onCancel = () => {
        if (shouldPut) {
            navigate(detailPage);
        } else {
            navigate("/");
        }
    }

    const { fields, append, replace, remove} = useFieldArray({
        control,
        name: "ingredients",
    });


    const form = (
        <div className="d-flex justify-content-center">
            <Form className="col-12 col-lg-9"
                  onSubmit={handleSubmit(onSubmit)}>
                <Table bordered hover style={{borderRadius: '5px', overflow: 'hidden'}}>
                    <tbody>
                {
                    propertyNames.map(propertyName => {
                        const displayName = propertyName.split("_")
                            .map(w => w.charAt(0).toUpperCase() + w.slice(1))
                            .join(" ");
                        const required = ["year", "color_code", "color_name", "manufacturer"].includes(propertyName);
                        return (
                            <tr key={propertyName}>
                                <td>{displayName}</td>
                                <td>
                                    <Form.Control type={propertyName === "formula_date" ? "date" : "text"}
                                                  {...register(propertyName, {required: required})}/>
                                </td>
                            </tr>
                        )
                    })
                }
                    </tbody>
                </Table>

                <h2>Ingredients</h2>
                <div className="d-flex justify-content my-3">
                    <Button className="me-2" variant="success" onClick={() => {
                        if (tonerAmount === undefined || selectedToner === undefined) {
                            return;
                        }
                        append({
                            product: selectedToner.toner_id,
                            description: selectedToner.name,
                            amount: +tonerAmount,
                        });
                        setSelectedToner(undefined);
                        setTonerAmount(0);
                    }}>+</Button>
                    <Typeahead
                        id="typeahead-toner"
                        placeholder="toner"
                        labelKey={toner=> `${toner.toner_id} - ${toner.name}`}
                        onChange={(selected) => {
                            setSelectedToner(selected[0]);
                        }}
                        options={toners ?? []}
                        selected={selectedToner ? [selectedToner] : []}
                    />
                    <Form.Control className="ms-2 w-25" type="text" placeholder="Amount" value={tonerAmount} onChange={(e) => setTonerAmount(e.target.value)} />

                </div>
                <Table striped bordered hover style={{borderRadius: '5px', overflow: 'hidden'}}>
                    <thead>
                    <tr>
                        <th>Product</th>
                        <th>Description</th>
                        <th>Amount</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {fields.map((field, index) => (
                        <tr key={field.id}>
                            <td>
                                <Form.Control
                                    type="text" disabled={true} {...register(`ingredients.${index}.product`, {required: true})} />
                            </td>
                            <td>
                                <Form.Control
                                    type="text" disabled={true} {...register(`ingredients.${index}.description`, {required: true})} />
                            </td>
                            <td>
                                <Form.Control
                                    type="text" {...register(`ingredients.${index}.amount`, {required: true})} />
                            </td>
                            <td>
                                <Button variant="danger" onClick={() => remove(index)}>-</Button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>

                <div className="my-2">
                    <Button variant="primary" className="me-2" type="submit">Save</Button>
                    <Button variant="secondary" onClick={onCancel}>Cancel</Button>
                </div>
            </Form>
        </div>
    );

    useEffect(() => {
        if (params.id) {
            getIngredient(params.id).then(data => {
                propertyNames.forEach(propertyName => {
                    setValue(propertyName, data.data[propertyName])
                })
                replace([]);
                data.data.ingredients.forEach(ingredient => append(ingredient));
            }).catch(handleHttpErrors);
        }
    }, [params]);


    return form;
}