import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';

import { HashRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Login from "./components/Login";
import Header from "./components/Header";
import Signup from "./components/Signup";
import ColorDetail from "./components/ColorDetail";
import Search from "./components/Search";
import Home from "./components/Home";
import {ColorEdit} from "./components/ColorEdit";
import Admin from "./components/Admin";
import Container from "react-bootstrap/Container";
import TonersPage from "./components/TonersPage";
import DispensePage from "./components/DispensePage";
import {AppContextProvider, UseAppContext} from "./hooks/appContext";
import ResetPassword from "./components/ResetPassword";
import SendPasswordReset from "./components/SendPasswordReset";

function App() {
    return (
        <div className="vh-100">
            <div  className="bg-secondary h-100 overflow-auto">
                <HashRouter>
                    <AppContextProvider>
                        <>
                        <Header/>
                        <Container className="bg-white rounded p-2 mt-3">
                            <Routes>
                                <Route path="/" element={<Layout/>}>
                                    <Route path={"/"} element={<Home/>} />
                                    <Route path={"home"} element={<Home/>} />
                                    <Route path={"login"} element={<Login/>} />
                                    <Route path={"signup"} element={<Signup/>} />
                                    <Route path={"color-detail/:id"} element={<ColorDetail key={window.location.pathname}/>} />
                                    <Route path={"dispense/:id"} element={<DispensePage key={window.location.pathname}/>} />
                                    <Route path={"color-edit/:id"} element={<ColorEdit key={window.location.pathname}/>} />
                                    <Route path={"color-add"} element={<ColorEdit/>} />
                                    <Route path={"search"} element={<Search/>} />
                                    <Route path={"variants/*"} element={<Search key={window.location.pathname}/>} />
                                    <Route path={"admin"} element={<Admin/>} />
                                    <Route path={"toners"} element={<TonersPage/>} />
                                    <Route path={"users/:id/reset-password"}
                                           element={<ResetPassword key={window.location.pathname}/>} />
                                    <Route path={"send-password-reset"}
                                           element={<SendPasswordReset key={window.location.pathname}/>} />
                                    <Route path={"toners"} element={<TonersPage/>} />
                                </Route>
                            </Routes>
                        </Container>
                            </>
                    </AppContextProvider>
                </HashRouter>
            </div>
        </div>
    );
}

export default App;
