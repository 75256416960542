import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {useState} from "react";
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import { useForm } from "react-hook-form";
import {UseAppContext} from "../hooks/appContext";
import FormError from "./FormError";
import {userSignUp} from "../api/users";
import {Row} from "react-bootstrap";


export default function Signup() {

    const [searchParams, setSearchParams] = useSearchParams();
    const [loggedIn, setLoggedIn, handleHttpErrors] = UseAppContext();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [formError, setFormError] = useState(null);

    const navigate = useNavigate();
    const navigateHome = () => navigate('/');

    // Validate passwords are identical and submit to register
    const onSubmit = async data => {
        if (data.password1 !== data.password2) {
            setFormError("Passwords must be identical");
            return
        } else {
            data.password = data.password1
            delete data.password1
            delete data.password2
        }
        try {
            userSignUp(data, searchParams.get("token")).catch(handleHttpErrors);
            navigateHome();
        } catch (e) {
            setFormError("Error signing up, please double check your inputs")
        }
    }

    if (loggedIn) {
        navigateHome();
    }

    return (
        <Row className="justify-content-center">
            <Form className="col-12 col-lg-6" onSubmit={handleSubmit(onSubmit)}>
                <Form.Group id="1" className="my-1" controlId="formUser">
                    <Form.Label>Username</Form.Label>
                    <Form.Control type="username" placeholder="Enter username" {...register("username", { required: true })}/>
                    {errors.username?.type === 'required' && <FormError text="Username required"/>}
                </Form.Group>

                <Form.Group id="1" className="my-1" controlId="formEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" placeholder="Enter email address" {...register("email", { required: true })}/>
                    {errors.email?.type === 'required' && <FormError text="Email address required"/>}
                </Form.Group>

                <Form.Group id="1" className="my-1" controlId="formFirstName">
                    <Form.Label>First name</Form.Label>
                    <Form.Control type="first_name" placeholder="Enter first name" {...register("first_name", { required: true })}/>
                    {errors.first_name?.type === 'required' && <FormError text="First name required"/>}
                </Form.Group>

                <Form.Group id="1" className="my-1" controlId="formLastName">
                    <Form.Label>Last name</Form.Label>
                    <Form.Control type="last_name" placeholder="Enter last name" {...register("last_name", { required: true })}/>
                    {errors.last_name?.type === 'required' && <FormError text="Last name required"/>}
                </Form.Group>

                <Form.Group className="my-1" controlId="formBasicPassword1">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Password" {...register("password1", { required: true })}/>
                    {errors.password1?.type === 'required' && <FormError text="Password required"/>}
                </Form.Group>

                <Form.Group className="my-1" controlId="formBasicPassword2">
                    <Form.Label>Repeat Password</Form.Label>
                    <Form.Control type="password" placeholder="Password" {...register("password2", { required: true })}/>
                    {errors.password2?.type === 'required' && <FormError text="Please reenter your password"/>}
                </Form.Group>

                <Form.Group className="my-1" controlId="formBasicSubmit">
                    <Button variant="primary mt-3" type="submit">Submit</Button>
                    <FormError text={formError}/>
                </Form.Group>
            </Form>
        </Row>
    );
}