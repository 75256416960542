import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {useState} from "react";
import {useNavigate} from 'react-router-dom';
import { useForm } from "react-hook-form";
import FormError from "./FormError";
import {Row} from "react-bootstrap";
import {sendPasswordReset} from "../api/users";

// I wrote this code

export default function SendPasswordReset() {
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const [formError, setFormError] = useState(null);

    const onSubmit = async data => {
        sendPasswordReset(data.email_address)
            .then(() => navigate("/login"))
            .catch(() => setFormError("Error sending password reset, please try again!"));
    }

    return (
        <Row className="justify-content-center">
            <Form className="col-12 col-lg-6" onSubmit={handleSubmit(onSubmit)}>
                <Form.Group id="1" className="my-1" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email address" {
                        ...register("email_address", { required: true })
                    }/>
                    {errors.username?.type === 'required' && <FormError text="Email address required"/>}
                </Form.Group>

                <Form.Group className="my-1" controlId="formBasicSubmit">
                    <Button variant="primary mt-3" type="submit">Send reset email</Button>
                    <FormError text={formError}/>
                </Form.Group>
            </Form>
        </Row>
    );
}