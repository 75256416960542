import React, { Component } from 'react';
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Container from "react-bootstrap/Container";

class Layout extends Component {
  render() {
    return (
    <Container className="mt-3">
      <Outlet />
    </Container>
    );
  }
}

export default Layout;