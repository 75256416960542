import axiosClient from "./client";

export async function getIngredient(color_code) {
  return axiosClient.get(`/ingredients/${color_code}/`);
}

export async function updateIngredient(color_code, data) {
  return axiosClient.put(`/ingredients/${color_code}/`, JSON.stringify(data));
}

export async function addIngredient(data) {
  return axiosClient.post(`/ingredients/`, JSON.stringify(data));
}

export async function deleteColor(color_code) {
  return axiosClient.delete(`/ingredients/${color_code}/`);
}

export async function search (search) {
  return await axiosClient.get(`/ingredients/?search=${search}`);
}