import axiosClient from "./client";


export async function userLogin(data) {
  return axiosClient.post("/users/login/", JSON.stringify(data));
}

export async function userLogout() {
  return axiosClient.get("/users/logout/");
}

export async function userSignUp(data, invite_token) {
    return axiosClient.post("/users/", JSON.stringify(data), {
        "headers": {
            "Registration-Token": invite_token
        }
    });
}

export async function userPasswordUpdate(user_id, data, token) {
    return axiosClient.put(`/users/${user_id}/`, JSON.stringify(data), {
        "headers": {
            "Password-Reset-Token": token
        }
    });
}

export async function sendInvite(email_address) {
    return axiosClient.get(`/users/invite/${email_address}/`)
}

export async function sendPasswordReset(email_address) {
    return axiosClient.get(`/users/password-reset/${email_address}/`)
}

