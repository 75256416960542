import Table from 'react-bootstrap/Table';
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {UseAppContext} from "../hooks/appContext";
import {deleteColor, getIngredient} from "../api/ingredients";
import {Col, Row} from "react-bootstrap";
import {isStaff} from "../api/auth";
import Button from "react-bootstrap/Button";

export default function ColorDetail() {

    const navigate = useNavigate();
    const navigateHome = () => navigate('/');
    const [loggedIn, setLoggedIn, handleHttpErrors] = UseAppContext();

    const [colorDetail, setColorDetail] = useState();
    const { id } = useParams();

    useEffect(() => {
        if (colorDetail) {
            return;
        }

        getIngredient(id).then(data => {
            setColorDetail(data.data);
        }).catch(handleHttpErrors);
    }, [id]);


    if (!colorDetail) {
        return (<></>);
    }

    const property_names = [
        "color_code",
        "color_name",
        "manufacturer",
        "year",
        "variant_description",
        "color_reference",
        "formula_version",
        "formula_date",
        "made_by",
        "quality",
        ...isStaff() ? [
            "user_cost",
            "cost_code",
            "jobber_cost"
        ] : []
    ]
    return (
        <div className="d-flex justify-content-center">
            <Col lg={8}>
                <Table bordered hover style={{borderRadius: '5px', overflow: 'hidden'}}>
                    <tbody>
                    {
                        property_names.map(name => {
                            const displayName = name.split("_")
                                .map(w => w.charAt(0).toUpperCase() + w.slice(1))
                                .join(" ");
                            return (<tr key={displayName}>
                                <td>{displayName}</td>
                                <td>{colorDetail[name]}</td>
                            </tr>);})
                    }
                    </tbody>
                </Table>
                <h2>Ingredients</h2>
                <Table striped bordered hover style={{borderRadius: '5px', overflow: 'hidden'}}>
                    <thead>
                    <tr>
                        <th>Product</th>
                        <th>Description</th>
                        <th>Amount</th>
                        {
                            isStaff() ? <>
                                <th>D Lb/Gal</th>
                                <th>D Gr/Ml</th>
                                <th>D Gr/Lit</th>
                            </> : <></>
                        }
                        <th>Price/Qt</th>
                        <th>Discount %</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        colorDetail["ingredients"].map(color => (<tr key={`ingredient-${color.product}`}>
                            <td>{color.product}</td>
                            <td>{color.description}</td>
                            <td>{color.amount}</td>
                            {
                                isStaff() ? <>
                                    <td>{(+color.density_lbpgl).toFixed(2)}</td>
                                    <td>{((color.density_lbpgl * 119.826) / 1000).toFixed(2)}</td>
                                    <td>{(color.density_lbpgl * 119.826).toFixed(2)}</td>
                                    </> : <></>
                            }
                            <td>{color.price_pqt}</td>
                            <td>{color.discount}</td>
                        </tr>))
                    }
                    </tbody>
                </Table>
            </Col>
             <Col className="ms-3" lg={1}>
                 <Button
                        className="my-1"
                        onClick={() => navigate(`/dispense/${id}`)}>
                        Dispense
                    </Button>
            {
                isStaff() ?
                    <>
                    <Button
                        className="my-1"
                        onClick={() => {
                            deleteColor(id).then(navigateHome).catch(handleHttpErrors);
                        }}
                        variant={"danger"}>
                        Delete
                    </Button>
                    <Button
                        className="my-1"
                        onClick={() => navigate(`/color-edit/${id}`)}
                        variant={"warning"}>
                        Edit
                    </Button>
                    </>
                 : <></>
            }
            </Col>
        </div>
    );
}