import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {useState} from "react";
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import { useForm } from "react-hook-form";
import FormError from "./FormError";
import {userPasswordUpdate} from "../api/users";
import {Row} from "react-bootstrap";


export default function ResetPassword() {

    const { id } = useParams();

    const [searchParams, setSearchParams] = useSearchParams();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [formError, setFormError] = useState(null);

    const navigate = useNavigate();
    const navigateHome = () => navigate('/');

    // Validate passwords are identical and submit to register
    const onSubmit = async data => {
        if (data.password1 !== data.password2) {
            setFormError("Passwords must be identical");
            return
        } else {
            data.password = data.password1
            delete data.password1
            delete data.password2
        }

        userPasswordUpdate(id, {
            "password": data.password
        }, searchParams.get("token"))
            .then(() => navigateHome())
            .catch(() => setFormError("Error signing up, please double check your inputs"));
    }

    return (
        <Row className="justify-content-center">
            <Form className="col-12 col-lg-6" onSubmit={handleSubmit(onSubmit)}>

                <Form.Group className="my-1" controlId="formBasicPassword1">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Password" {...register("password1", { required: true })}/>
                    {errors.password1?.type === 'required' && <FormError text="Password required"/>}
                </Form.Group>

                <Form.Group className="my-1" controlId="formBasicPassword2">
                    <Form.Label>Repeat Password</Form.Label>
                    <Form.Control type="password" placeholder="Password" {...register("password2", { required: true })}/>
                    {errors.password2?.type === 'required' && <FormError text="Please reenter your password"/>}
                </Form.Group>

                <Form.Group className="my-1" controlId="formBasicSubmit">
                    <Button variant="primary mt-3" type="submit">Submit</Button>
                    <FormError text={formError}/>
                </Form.Group>
            </Form>
        </Row>
    );
}