import {forwardRef, useRef} from "react";
import {useParams} from "react-router-dom";

import {useReactToPrint} from "react-to-print";
import Dispense from "./Dispense";
import {PrinterFill} from "react-bootstrap-icons";
import Button from "react-bootstrap/Button";

export default function DispensePage() {

    const { id } = useParams();

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });

    if (!id) {
        return <></>;
    }

    const ForwardingDispense = forwardRef((props, ref) => (Dispense(props, ref)));

  return (
      <div>
          <Button variant={"secondary"} onClick={handlePrint}><PrinterFill/></Button>
          <ForwardingDispense id={id} ref={componentRef}/>
      </div>
  );
}