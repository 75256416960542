import {useParams, useSearchParams} from "react-router-dom";
import {useState, useEffect} from "react";
import {search} from "../api/ingredients";
import {Col, Row} from "react-bootstrap";
import ColorTile from "./ColorTile";
import {UseAppContext} from "../hooks/appContext";

// I wrote this code
export default function Search() {

    const [loggedIn, setLoggedIn, handleHttpErrors] = UseAppContext();
    const [searchParams, setSearchParams] = useSearchParams();
    const params = useParams();
    const [searchResults, setSearchResults] = useState(null);

      useEffect(() => {
          const is_variants_page = !!params["*"];
          const query = is_variants_page ? params["*"] : searchParams.get("query");
          const unique_color_codes = new Map();
          search(query).then(data => {
              if (!Array.isArray(data.data.results) || data.data.results.length === 0) {
                  return;
              }
              data.data.results.forEach(result => {
                  const key = is_variants_page ?
                      `${result.color_code}-${result.year}-${result.variant_description}` :
                      `${result.color_code}-${result.year}`;
                  const value = {
                      color_code: result.color_code,
                      color_name: result.color_name,
                      manufacturer: result.manufacturer,
                      year: result.year,
                      show_variant: is_variants_page,
                      key: key,
                  };
                  if (is_variants_page) {
                      value["variant_description"] = result.variant_description;
                      value["color_id"] = result.color_id;
                  }
                  unique_color_codes[key] = value;
              });
              const result = Object.values(unique_color_codes)
                  .sort((a, b) => a.key.localeCompare(b.key));
              setSearchResults(result);
          }).catch(handleHttpErrors);
      }, [searchParams]);

      // Show profile tiles for each result in search
      return searchResults != null ? (
          <Row className="p-3">
              {
                  searchResults.length > 0 ?
                  searchResults.map(result => (
                      <Col className="my-3" sm={6} md={4} lg={3} key={result.key}>
                          <ColorTile {...result}/>
                      </Col>)) : <>No results found</>
              }
          </Row>
      ) : (<div></div>)
}