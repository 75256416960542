import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import {UseAppContext} from "../hooks/appContext";
import {isStaff, logout} from "../api/auth";
import {useNavigate} from "react-router-dom";

// I wrote this code

export default function Header() {
    const [loggedIn, setLoggedIn, handleHttpErrors] = UseAppContext();
    const navigate = useNavigate();

    return (
        <header className="p-3 text-bg-dark">
            <Navbar bg="dark" variant="dark">
                <Container>
                    <Navbar.Brand href="#/home">
                        <img src="/logo.png" width="50" height="50" className="d-inline-block align-top" alt="logo"/>
                    </Navbar.Brand>
                    <Nav className="me-auto">
                        <Nav.Link href="#/home">Home</Nav.Link>
                        {
                            loggedIn ?
                                <Nav.Link href="#/toners">Toners</Nav.Link> : <></>
                        }
                        {
                            loggedIn && isStaff()?
                                <Nav.Link href="#/admin">Admin</Nav.Link>
                                : <></>
                        }
                    </Nav>

                    {loggedIn ?
                        <div className="px-1">
                            <Button variant="primary" size="sm" onClick={() => {
                                void logout();
                                setLoggedIn(false);
                                navigate("/");
                            }}>Logout</Button>
                        </div>
                        :
                        <>
                        </>
                    }
                </Container>
            </Navbar>
        </header>
    );
}
