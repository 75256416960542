import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {isLoggedIn, logout} from "../api/auth";
import {useNavigate} from "react-router-dom";

const AppContext = createContext(undefined);

// Hook to provide access to context object
export const UseAppContext = () => {
  return useContext(AppContext);
};

export const AppContextProvider = (props) => {
  // Share the loggedIn state across all children
  const [loggedIn, setLoggedIn] = useState(isLoggedIn());

   const navigate = useNavigate();
    const navigateHome = () => navigate('/');

  const handleHttpErrors = (httpError) => {
    console.log("Handling", httpError);
    const errorCode = httpError?.response?.status;
    if (errorCode === 401) {
      logout().catch(() => console.log("Failed to logout"));
      setLoggedIn(false);
      navigateHome();
    }
  }

  // Assign React state and constants to context object
  const AppContextObject = [loggedIn, setLoggedIn, handleHttpErrors];
  return (
    <AppContext.Provider value={AppContextObject}>
      {props.children}
    </AppContext.Provider>
  );
};

AppContextProvider.propTypes = {
  children: PropTypes.element,
};