import Card from 'react-bootstrap/Card';

export default function ColorTile(props) {
    const {
        color_code,
        color_name,
        manufacturer,
        year,
        show_variant
    } = props;
    return (
        <Card>
            <Card.Body>
                {
                    show_variant ?
                        <a href={`#/color-detail/${props.color_id}`} className="stretched-link"/> :
                        <a href={`#/variants/${color_code}`} className="stretched-link"/>
                }
                <Card.Title>{color_code}</Card.Title>
                <Card.Text>
                    <b>{color_name}</b><br/>
                    <b>{manufacturer}</b><br/>
                    {year}
                    {show_variant ? <><br/>{props.variant_description}</> : <></>}
                </Card.Text>
            </Card.Body>
        </Card>
    );
}