import axiosClient from "./client";

export async function getToner(toner_id) {
  return axiosClient.get(`/ingredients/toners/${toner_id}/`);
}

export async function listToners() {
  return axiosClient.get(`/ingredients/toners/`);
}

export async function updateToner(toner_id, data) {
  return axiosClient.put(`/ingredients/toners/${toner_id}/`,  JSON.stringify(data));
}

export async function createToner(data) {
  return axiosClient.post(`/ingredients/toners/`,  JSON.stringify(data));
}

export async function deleteToner(toner_id) {
  return axiosClient.delete(`/ingredients/toners/${toner_id}/`);
}