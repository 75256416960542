import {useEffect, useState} from "react";
import {listToners} from "../api/toner";
import Table from "react-bootstrap/Table";
import TonerRow from "./TonerRow";
import {isStaff} from "../api/auth";
import Button from "react-bootstrap/Button";
import {Plus} from "react-bootstrap-icons";
import {UseAppContext} from "../hooks/appContext";

export default function TonersPage() {

    const [loggedIn, setLoggedIn, handleHttpErrors] = UseAppContext();
    const [ toners, setToners] = useState(undefined);

    useEffect(() => {
        if (toners) {
            return;
        }

        listToners().then(data => {
            setToners(data.data.results);
        }).catch(handleHttpErrors);
    }, [toners]);

    if (! toners) {
        return (<></>);
    }

    const refresh = () => setToners(undefined);

    return (
        <>
            {
                isStaff() ? <Button className="mb-2" variant="success" onClick={
                    () => setToners([
                        {toner_id: ""},
                        ...toners
                    ])
                }><Plus/></Button> : <></>
            }
            <Table striped bordered hover style={{borderRadius: '5px', overflow: 'hidden'}}>
                <thead>
                <tr>
                    {isStaff() ? <th className="col-1"></th> : <></>}
                    <th>Product</th>
                    <th>Description</th>
                    { isStaff() ? <>
                            <th>Density Lb/Gal</th>
                            <th>Density Gr/Ml</th>
                            <th>Density Gr/Lit</th>
                        </>: <></>
                    }
                    <th>Price/Qt</th>
                    <th>Discount %</th>
                </tr>
                </thead>
                <tbody>
                {
                    toners.map(toner => (
                            <TonerRow  key={`toner-${toner.toner_id ?? Math.floor(Math.random() * 1000)}`}
                                       toner_id={toner.toner_id} refresh={refresh}/>
                    ))
                }
                </tbody>
            </Table>
        </>
    );
}