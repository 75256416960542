import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {useState} from "react";
import {login} from "../api/auth";
import {useNavigate} from 'react-router-dom';
import { useForm } from "react-hook-form";
import {UseAppContext} from "../hooks/appContext";
import FormError from "./FormError";
import {Row} from "react-bootstrap";

export default function Login() {

    const [loggedIn, setLoggedIn, handleHttpErrors] = UseAppContext();
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const [formError, setFormError] = useState(null);

    const navigateHome = () => navigate('/');

    const onSubmit = async data => {
        const success = await login(data.username, data.password);
        if (success) {
            setLoggedIn(true);
            navigateHome();
        } else {
            setFormError("Invalid credentials, please try again!")
        }
    }

    if (loggedIn) {
        navigateHome();
    }

    return (
        <Row className="justify-content-center">
            <Form className="col-12 col-lg-6" onSubmit={handleSubmit(onSubmit)}>
                <Form.Group id="1" className="my-1" controlId="formBasicEmail">
                    <Form.Label>Username</Form.Label>
                    <Form.Control type="username" placeholder="Enter username" {...register("username", { required: true })}/>
                    {errors.username?.type === 'required' && <FormError text="Username required"/>}
                </Form.Group>

                <Form.Group className="my-1" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Password" {...register("password", { required: true })}/>
                    {errors.password?.type === 'required' && <FormError text="Password required"/>}
                </Form.Group>

                <div className="d-flex">
                    <Form.Group className="my-1" controlId="formBasicSubmit">
                        <Button variant="primary" className="mt-3" type="submit">Submit</Button>
                        <FormError text={formError}/>
                    </Form.Group>

                    <Form.Group className="my-1" controlId="formPasswordReset">
                        <Button variant="secondary" className="mt-3 ms-2"
                                onClick={() => navigate("/send-password-reset")}>Reset Password</Button>
                    </Form.Group>
                </div>
            </Form>
        </Row>
    );
}