import Form from "react-bootstrap/Form";
import {useState} from "react";
import {UseAppContext} from "../hooks/appContext";
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import Login from "./Login";
import Button from "react-bootstrap/Button";
import {InputGroup} from "react-bootstrap";

export default function Home() {
    const  [loggedIn, setLoggedIn, handleHttpErrors] = UseAppContext();
    const [searchValue, setSearchValue] = useState("");

    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const navigate = useNavigate();

    // Navigate to search page and clear the search box on search submit
    const onSubmit = async data => {
        navigate(`/search?query=${data.query}`, {replace: true});
        setSearchValue("");
    }

    return loggedIn ? (
        <div className="d-flex justify-content-center">
        <Form className="d-flex col-md-4" onSubmit={handleSubmit(onSubmit)}>
            <InputGroup>
                <Form.Control
                    type="search"
                    placeholder="Search"
                    className="me-3"
                    aria-label="Search"
                    {...register("query", { required: true })}
                    value={searchValue}
                    onInput={(e) => setSearchValue(e.target.value)}
                />
                     <Button type="submit">Search</Button>
            </InputGroup>
        </Form>
            </div>

    ) : <Login/>
}
