import {UseAppContext} from "../hooks/appContext";
import {isStaff} from "../api/auth";
import Container from "react-bootstrap/Container";
import {Col, Overlay, Popover, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {useNavigate} from "react-router-dom";
import Form from "react-bootstrap/Form";
import {useState} from "react";
import {sendInvite} from "../api/users";
import Popup from "reactjs-popup";

export default function Admin() {
    const [loggedIn, setLoggedIn, handleHttpErrors] = UseAppContext();
    const navigate = useNavigate();
    const [newUserEmail, setNewUserEmail] = useState("");

    const [popupMessage, setPopupMessage] = useState("");
    const [popupOpen, setPopupOpen] = useState(false);
    const [target, setTarget] = useState(null);
    const closePopup = () => {
        setPopupOpen(false);
    }

    return (
        <>
            {!loggedIn || !isStaff() ?
                <Row className="bg-white">You don't have access to this page</Row>
                :
                <>
                    <Row className="my-2">
                        <Col lg={3} sm={6}>Add new color</Col>
                        <Col><Button variant="primary" onClick={() => navigate("/color-add")}>+</Button></Col>
                    </Row>
                    <Row className="my-2">
                        <Col lg={3} sm={6}>Invite new user</Col>
                        <Col>
                                <Form.Control
                                    type="search"
                                    placeholder="Email address"
                                    className="me-3"
                                    aria-label="Search"
                                    value={newUserEmail}
                                    onInput={(e) => setNewUserEmail(e.target.value)}
                                />
                        </Col>

                        <Overlay
                            className="mt-3"
                            show={popupOpen}
                            placement="top"
                            containerPadding={20}
                            target={target}>
                            <Popover id="popover-contained">
                                <Popover.Body>
                                    <div>{popupMessage}</div>
                                </Popover.Body>
                            </Popover>
                        </Overlay>

                        <Col lg={2} sm={1}> <Button onClick={(e) => {
                            if (!newUserEmail) {
                                return;
                            }
                            sendInvite(newUserEmail).then(() => {
                                setPopupMessage(`Invitation sent to ${newUserEmail}`);
                                setTarget(e.target);
                                setPopupOpen(true);
                                setNewUserEmail("");
                                setTimeout(() => setPopupOpen(false), 2000);
                            }).catch(handleHttpErrors);
                        }}>Invite</Button></Col>
                    </Row>
                </>
            }
        </>
    );
}