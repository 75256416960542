import axios from "axios";

const backend_base_url = process.env?.REACT_APP_BACKEND_BASE_URL ?? "https://color-api.catamountcoatings.com";

console.log(process.env.NODE_ENV);

const axiosClient = axios.create({
    baseURL: backend_base_url,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
});

axiosClient.interceptors.request.use(
    (config) => {
        // Inject auth token for requests
        const token = localStorage.getItem("x-coating-auth-token");
        if (token) {
            config.headers.Authorization = `Token ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

export default axiosClient;
