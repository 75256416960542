import {userLogin, userLogout} from "./users";

// I wrote this code
export async function login(username, password) {
    try {
        const { data: { token, staff } } = await userLogin({username, password});
        localStorage.setItem("x-coating-auth-token", token);
        localStorage.setItem("x-coating-staff", staff);
        return true;
    } catch (e) {
        return false;
    }
}

export async function logout() {
    userLogout().catch(console.log).finally(() => {
        localStorage.removeItem("x-coating-auth-token");
        localStorage.removeItem("x-coating-staff");
    });
}

export function isStaff() {
    return localStorage.getItem("x-coating-staff") === "true";
}

export function isLoggedIn() {
    return localStorage.getItem("x-coating-auth-token") != null
}