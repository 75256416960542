import Card from 'react-bootstrap/Card';
import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {getIngredient} from "../api/ingredients";
import {createToner, deleteToner, getToner, updateToner} from "../api/toner";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {Check, Plus, ThreeDots} from "react-bootstrap-icons";
import {isStaff} from "../api/auth";
import {UseAppContext} from "../hooks/appContext";

export default function TonerRow(props) {

    const [loggedIn, setLoggedIn, handleHttpErrors] = UseAppContext();
    const { toner_id, refresh } = props;

    const creating = toner_id === "";

    const [editing, setEditing] = useState(creating);
    const { control, register, handleSubmit, watch, formState: { errors }, setValue, getValues} = useForm();

    const [ toner, setToner] = useState();

    useEffect(() => {
        if (toner || creating) {
            return;
        }

        getToner(toner_id).then(data => {
            setToner(data.data);
        }).catch(handleHttpErrors);
    }, [toner]);

    const row = !toner && !creating ? <></> : (
        !editing ?
            <>
                <td>{toner.toner_id}</td>
                <td>{toner.name}</td>
                {
                    isStaff()? <>
                        <td>{(+toner.density_lbpgl).toFixed(2)}</td>
                        <td>{((toner.density_lbpgl * 119.826) / 1000).toFixed(2)}</td>
                        <td>{(toner.density_lbpgl * 119.826).toFixed(2)}</td>
                    </> : <></>
                }
                <td>{toner.price_pqt}</td>
                <td>{toner.discount}</td>
            </> :
            <>
                <td>
                    {creating ? <Form.Control type="text" {...register(`toner_id`)} /> : <>{toner.toner_id}</>}
                </td>
                <td>
                    <Form.Control type="text" {...register(`name`)} />
                </td>
                <td>
                    <Form.Control type="text" {...register(`density_lbpgl`, {
                        onChange: (e) => {
                            const lbpgl = getValues("density_lbpgl");
                            const grplt = (lbpgl * 119.826).toFixed(2);
                            const grpml = (grplt / 1000).toFixed(2);
                            setValue("density_grpml", grpml);
                            setValue("density_grplt",grplt);
                        }
                    })} />
                </td>
                <td>
                    <Form.Control type="text" {...register(`density_grpml`, {
                        onChange: (e) => {
                            const grpml = getValues("density_grpml");
                            const grplt = (grpml * 1000).toFixed(2);
                            const lbpgl = (grpml * 8.345).toFixed(2);
                            setValue("density_lbpgl", lbpgl);
                            setValue("density_grplt", grplt);
                        }
                    })} />
                </td>
                <td>
                    <Form.Control type="text" {...register(`density_grplt`, {
                        onChange: (e) => {
                            const grplt = getValues("density_grplt");
                            const lbpgl = (grplt * 0.0083).toFixed(2);
                            const grpml = (grplt / 1000).toFixed(2);
                            setValue("density_lbpgl", lbpgl);
                            setValue("density_grpml", grpml);
                        }
                    })} />
                </td>
                <td>
                    <Form.Control type="text" {...register(`price_pqt`)} />
                </td>
                <td>
                    <Form.Control type="text" {...register(`discount`)} />
                </td>
            </>
    );

    useEffect(() => {
        if (!creating && toner) {
            setValue("toner_id", toner_id);
            setValue("name", toner.name);
            setValue("price_pqt", toner.price_pqt);
            setValue("discount", toner.discount);
            setValue("density_lbpgl", toner.density_lbpgl);
            const grplt = (toner.density_lbpgl * 119.826).toFixed(2);
            const grpml = (grplt / 1000).toFixed(2);
            setValue("density_grpml", grpml);
            setValue("density_grplt",grplt);
        }
    }, [toner]);

    return (
        <tr className={toner && toner.deleted ? "table-warning" : ""}>
            { isStaff() ?
                <td style={{textAlign: 'center'}}>{
                    !editing ?
                        (toner && toner.deleted ?
                            <Button variant="primary" className="me-2 p-2" onClick={() => (
                                updateToner(toner_id, {
                                    ...toner,
                                    "deleted": false,
                                }).then(() => setToner({
                                    ...toner,
                                    deleted: false,
                                }))
                            )}>
                                <Plus/>
                            </Button>
                            :
                            <>
                                <Button variant="danger" className="mx-2 p-2" onClick={() => (
                                    updateToner(toner_id, {
                                        ...toner,
                                        "deleted": true,
                                    }).then(() => setToner({
                                        ...toner,
                                        deleted: true,
                                    }))
                                )}>
                                    -
                                </Button>
                                <Button variant="warning" className="p-2" onClick={() => (setEditing(true))}>
                                    <ThreeDots/>
                                </Button>
                            </>)
                        :
                        <Button className="me-2 p-2" onClick={() => {
                            if (!creating) {
                                updateToner(toner_id, {
                                    "toner_id": toner_id,
                                    "name": getValues("name"),
                                    "price_pqt": getValues("price_pqt"),
                                    "discount": getValues("discount"),
                                    "density_lbpgl": getValues("density_lbpgl"),
                                }).then(() => setEditing(false))
                                    .then(() => setToner(null));
                            } else {
                                createToner({
                                    "toner_id": getValues("toner_id"),
                                    "name": getValues("name"),
                                    "price_pqt": getValues("price_pqt"),
                                    "discount": getValues("discount"),
                                    "density_lbpgl": getValues("density_lbpgl"),
                                }).then(refresh);
                            }
                        }}>
                            <Check/>
                        </Button>
                }</td>: <></>
            }
            {
                row
            }
        </tr>
    );
}